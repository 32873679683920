import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLink target="_blank">
        Homepage
      </SocialLink>
      <SocialLink href="https://gitlab.jsilva.org" target="_blank">
        Gitlab
      </SocialLink>
      <SocialLink target="_blank">
        Linkedin
      </SocialLink>
      {/* Add more SocialLinks as required */}
    </FooterContainer>
  );
};

export default Footer;

export const FooterContainer = styled.footer`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--matrix-dark);
  border-top: 2px solid var(--matrix-green);
  margin-top: auto; /* Push the footer to the bottom of the container */
`;

export const SocialLink = styled.a`
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s ease;

  &:hover {
    color: #66ff66;
  }
`;
