import React from 'react';
import styled from 'styled-components';

const EducationStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    color: var(--matrix-green);
    background-color: var(--matrix-dark);

    h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .education-card {
        max-width: 600px;
        width: 100%;
        box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
        padding: 20px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);

        h2, h3, p {
            margin-bottom: 1rem;
        }
    }

    .magnify-icon {
        margin-left: 10px;
        cursor: pointer;
        transition: fill 0.3s ease;
    
        &:hover {
            fill: #66ff66;
        }
    }
`;

function Education() {
    return (
        <EducationStyled>
            <h1>Education</h1>

            <div className="education-card">
                <h2>MSc. Artificial Intelligence Engineering</h2>
                <h3>ISEP - Instituto Superior de Engenharia do Porto, Porto</h3>
                <p>September 2020 - September 2022</p>
                <p>Key Achievements:</p>
                <ul>
                
                    <li>
                        Publication: Climatization and luminosity optimization of buildings using genetic algorithm, random forest, and regression models.
                        <a href="https://energyinformatics.springeropen.com/articles/10.1186/s42162-021-00151-x" target="_blank" rel="noopener noreferrer" title="View publication">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                width="24" 
                                height="24"
                                className="magnify-icon"
                            >
                                <path 
                                    fill="var(--matrix-green)"
                                    d="M21.71,20.29l-6.81-6.81A7.74,7.74,0,1,0,10.23,18,7.66,7.66,0,0,0,16,16.64l6.81,6.81a1,1,0,0,0,1.41,0A1,1,0,0,0,21.71,20.29ZM12,16A6,6,0,1,1,18,10,6,6,0,0,1,12,16Z"
                                />
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="education-card">
                <h2>Bsc. Computer Science</h2>
                <h3>ISEP - Instituto Superior de Engenharia do Porto, Porto</h3>
                <p>September 2015 - July 2019</p>
                <p>Key Achievements:</p>
                <ul>

                <li>
                        Publication: Ensemble learning for electricity consumption forecasting in office buildings.
                        <a href="https://www.sciencedirect.com/science/article/abs/pii/S0925231220307372" target="_blank" rel="noopener noreferrer" title="View publication">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                width="24" 
                                height="24"
                                className="magnify-icon"
                            >
                                <path 
                                    fill="var(--matrix-green)"
                                    d="M21.71,20.29l-6.81-6.81A7.74,7.74,0,1,0,10.23,18,7.66,7.66,0,0,0,16,16.64l6.81,6.81a1,1,0,0,0,1.41,0A1,1,0,0,0,21.71,20.29ZM12,16A6,6,0,1,1,18,10,6,6,0,0,1,12,16Z"
                                />
                            </svg>
                        </a>
                    </li>

                    <li>
                        Publication: Energy Consumption Forecasting Using Ensemble Learning Algorithms.
                    <a href="https://link.springer.com/chapter/10.1007/978-3-030-23946-6_1" target="_blank" rel="noopener noreferrer" title="View publication">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                width="24" 
                                height="24"
                                className="magnify-icon"
                            >
                                <path 
                                    fill="var(--matrix-green)"
                                    d="M21.71,20.29l-6.81-6.81A7.74,7.74,0,1,0,10.23,18,7.66,7.66,0,0,0,16,16.64l6.81,6.81a1,1,0,0,0,1.41,0A1,1,0,0,0,21.71,20.29ZM12,16A6,6,0,1,1,18,10,6,6,0,0,1,12,16Z"
                                />
                            </svg>
                        </a>

                    </li>
                </ul>
            </div>

        </EducationStyled>
    );
}

export default Education;