import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import GlobalStyle from './GlobalStyles';

import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import CodeFall from './components/CodeFall';
import CharGrid from "./components/CharGrid"; 
import ExperiencePage from './components/ExperiencePage';
import Education from "./components/Education";
import Projects from "./components/Projects";
import Skills from './components/Skills'; 


function App() {
  return (
    <Router>
      <div className="App">
        <GlobalStyle />
        <CharGrid /> 
        <Navbar />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/experience" element={<ExperiencePage />} />
          <Route path="/education" element={<Education />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/skills" element={<Skills />} /> 
      </Routes>
        <Footer />
      </div>
    </Router>
  );
}


export default App;