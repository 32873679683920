// Skills.js
import React from 'react';
import styled from 'styled-components';
import SkillPillar from './SkillPillar';

const SkillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
`;

const skillsData = [
    { name: 'C#', proficiency: 90 },
    { name: 'Entity Framwork & Dapper', proficiency: 85 },
    { name: 'WPF and Winforms', proficiency: 80 },
    { name: 'PostgreSQL', proficiency: 75 },
    { name: 'Python', proficiency: 70 },
    { name: 'JavaScript', proficiency: 70 },
    { name: 'React', proficiency: 65 },
    { name: 'Node.js', proficiency: 60 },
    { name: 'CSS', proficiency: 65 },
    { name: 'Java', proficiency: 65 },
    { name: 'PL/SQL', proficiency: 65 },
    { name: 'Git', proficiency: 65 },
    { name: 'Linux', proficiency: 65 },
    { name: 'Rust', proficiency: 85 },
    { name: 'Substrate Framework', proficiency: 85 },
    { name: 'Machine Learning', proficiency: 95 },
    { name: 'Data Analysis', proficiency: 95 },
    
    
    // Add more skills as needed
];

const Skills = () => {
    return (
        <SkillsContainer>
            {skillsData.map(skill => (
                <SkillPillar key={skill.name} skill={skill.name} proficiency={skill.proficiency} />
            ))}
        </SkillsContainer>
    );
};

export default Skills;