import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* Fonts */
  @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

  :root {
      --matrix-green: #33FF33;
      --matrix-dark: #000000;
      --matrix-code-length: 20;
      --matrix-code-char: "1", "0"; /* You can add more characters here */
  }

  body, html {
    height: 100%;
}


.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }


  * {
    box-sizing: border-box;
  }

  body {
      margin: 0;
      padding: 0;
      font-family: 'VT323', monospace;
      color: var(--matrix-green);
      background-color: var(--matrix-dark);
      overflow-x: hidden;
     
  }

  a {
      color: var(--matrix-green);
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #66ff66;
      }
  }

  
  @keyframes fallingCode {
      0% {
          transform: translateY(-25vh);
          opacity: 0;
      }
      10% {
          opacity: 0.9;
      }
      85% {
          opacity: 0.9;
      }
      100% {
          transform: translateY(110vh);
          opacity: 0;
      }
  }

  .code-fall {
    animation: fallingCode linear infinite;
    animation-duration: 5s;
    animation-delay: calc(-5s * var(--animation-order));
    position: absolute;
    top: 0;
    font-size: calc(1em + var(--font-size-variance));
    filter: brightness(150%) glow(var(--matrix-green) 0.2em 0.2em 1em);
    width: 1em;
    height: 100vh;
    overflow: hidden;
    white-space: nowrap;

    
    content: "1010101010101010101010101010";
    letter-spacing: 0.2em;
    line-height: 1.5em;
    opacity: 0;
    }

    .code-fall-container {
        display: flex;
        justify-content: space-between;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
    }

  button {
      border: 2px solid var(--matrix-green);
      background: transparent;
      color: var(--matrix-green);
      padding: 8px 16px;
      font-size: 0.9em;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
          background: var(--matrix-green);
          color: var(--matrix-dark);
      }
  }

  .terminal {
    background-color: #000;
    color: var(--matrix-green);
    font-family: 'VT323', monospace;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    margin: 40px auto;
  }
  
  .command-prompt {
    color: #33FF33;
    ::before {
      content: "> ";
    }
  }


  .char-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1em;
    line-height: 1em;
    display: flex;
    flex-wrap: wrap;
    pointer-events: none;  
    overflow: hidden;
    z-index: 1;  
}
.char-cell {
    opacity: 0.015;  
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s, color 0.5s;
}

.char-cell.active {
    opacity: 1; 
    color: #99ff99;  
}

.char {
    color: transparent;  // Making all characters initially transparent
    animation: lightUp 1.5s infinite;
}

.matrix-rain {
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    color: #0F0;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.matrix-rain:after {
    content: "1 0 1 1 0 1 0 0 1 0 1 1 0 1 0 0 1 0 1 1 0 1 0 0 1 0";
    display: block;
    animation: matrix 1s infinite linear;
    line-height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
    height: 200%;
}

@keyframes matrix {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
}

.project-card {
    position: relative;
    max-width: 720px; /* Set a maximum width for responsiveness */
    width: auto;
    border: 1px solid #0F0;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s ease;
    margin: 0 auto; /* Center horizontally */
    margin-bottom:5px;
    
    /* Add matrix rain styles for the project card */
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    color: #0F0;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.project-card:hover .matrix-rain:after {
    animation-play-state: paused;  // pause the matrix rain on hover
    opacity: 0.2; // make it transparent on hover
}

.project-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: all 0.5s ease;
}

.project-card:hover .project-details {
    opacity: 1; // reveal project details on hover
}

.project-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start; /* Change align-items to flex-start */
    min-height: 100vh;
    flex-direction: column; /* Add this to make the layout vertical */
}

`;

export default GlobalStyle;