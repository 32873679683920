// components/Navbar.js

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavbarStyled = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: rgba(0, 0, 0, 0.9);  // Slightly transparent black

  a {
    margin: 0 15px;
    font-size: 1.2rem;
  }
`;

export const NavbarContainer = styled.nav`
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    background-color: var(--matrix-dark);
    border-bottom: 2px solid var(--matrix-green);
    z-index: 10;
`;

export const NavItem = styled.a`
    margin: 0 20px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

function Navbar() {
  return (
    <NavbarStyled>
      <Link to="/">José Silva</Link>
      <div>
        <Link to="/experience"> Experience</Link>
        <Link to="/education"> Education</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/skills">Skills</Link>
        </div>
    </NavbarStyled>
  );
}

export default Navbar;
