import React from 'react';
import styled from 'styled-components';

// Define colors at the top, which can be re-used
const matrixGreen = 'var(--matrix-green)';
const transparentGreen = 'rgba(0, 255, 0, 0.1)';

const PillarContainer = styled.div`
    position: relative;
    width: 100px;
    height: ${props => props.height}px;
    border: 2px solid ${matrixGreen};
    background-color: ${transparentGreen};
`;

const PillarContent = styled.div`
    height: ${props => props.height}%; 
    background-color: ${matrixGreen};
    position: relative;
`;

const SkillName = styled.span`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    font-weight: bold;
`;

const MatrixCode = styled.span`
    position: absolute;
    top: 0;
    animation: fall 5s infinite;
    opacity: 0.7;

    @keyframes fall {
        from { top: -100%; }
        to { top: 100%; }
    }
`;

const SkillLabel = styled.span`
    position: absolute;
    top: -20px;
    color: ${matrixGreen};
`;

const SkillPillar = ({ skill, proficiency }) => {
    const pillarHeight = proficiency; // Convert proficiency to height

    return (
        <div style={{position: 'relative', width: '100px', margin: '30px 10px'}}>
            <SkillLabel>{skill}</SkillLabel>
            <PillarContainer height={pillarHeight}>
                <PillarContent height={pillarHeight}>
                    <MatrixCode />
                    <SkillName>{skill}</SkillName>
                </PillarContent>
            </PillarContainer>
        </div>
    );
};

export default SkillPillar;