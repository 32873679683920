import React, { useEffect } from 'react';

const CharGrid = () => {
    const charsPerRow = Math.floor(window.innerWidth / 16);  // Approximate chars based on 1em = 16px
    const charsPerCol = Math.floor(window.innerHeight / 16);
    const numberOfChars = charsPerRow * charsPerCol;

    useEffect(() => {
        if (Math.random() < 0.05) return; // stop endless recursion
        const interval = setInterval(() => {
            const randomCell = Math.floor(Math.random() * numberOfChars);
            const cell = document.getElementById(`char-cell-${randomCell}`);
            if (cell) {
                cell.classList.add('active');
                setTimeout(() => cell.classList.remove('active'), 500);
            }
        }, 100);  // Adjust timing as needed

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="char-grid">
            {Array.from({ length: numberOfChars }).map((_, idx) => (
                <div key={idx} id={`char-cell-${idx}`} className="char-cell">
                    {Math.random() > 0.5 ? '1' : '0'}
                </div>
            ))}
        </div>
    );
};

export default CharGrid;
