import React from 'react';
import MatrixProjectCard from './MatrixProjectCard';

function Projects() {
    // Sample project data; replace this with your actual projects
    const projectData = [
        {
            title: "Project 1: Portfolio App",
            description: "This project aim for developing a console/matrix theme portfolio app using react."+
            "The idea was for the background to be dinamic was this was achieved using a char grid that randomly lights up 1's and 0's in the background. "+
            "It also has a custom card where the card its not hovered the content has a feeling of being decrypted and when its hovered the real content is revealed."+
            "\n The link can be found in https://portfolio.jsilva.org"
            ,
            skills: ["React", "CSS","Javascript","Styled Components"],
        },
        // other projects
        {
            title: "Project 2: Basic React Server Homepage",
            description: "This project aim to developed a simple react page to redirect me to other pages within my domain while also displaying usefull info about"+
            " a sever that will run the backend service. To add some style to it, it was developed with a tron style for the buttons and the panels with a background "+
            "being a dynamic outter space view of earth. The link for this can be found in https://jsilva.org",
            skills: ["React", "CSS","Javacript","WebGL"]
        },
        {
            title: "Project 3: Simple Server Info API",
            description: "This project's goal was to develop a simple api to feed a front app with a server's information like CPU usage, ram usage and disk space."+
            "This way of separating the the frontend and the backend allows for easily switch the server being monitored and also the possibility of running multiple instances.",
            skills: ["Javascript", "Express"]
        },
        {
            title: "Project 4: DevOps 101 ",
            description: "This project consisted of focusing on the deployment of apps and the maintenence of usefull services within my domain. For this,"+
            "i rented a simple 4-core VPS from contabo in which i decided to test the deployment of real apps with full production builds in a certified domain."+
            "I also hosted my own gitlab server within to have a greater flexibility in future CI/CD tasks. I also deployed a mail server, namely the open source iRedMail, to serve as my gitlab server support system."+
            "I manage to get everything running under my domain (jsilva.org) and it currently has 0 downtime. Also for allowing users to view usefull documents, i've hosted a simple archive server.",
            skills: ["Cloud Services", "Linux","NGINX","GITLAB","Domain Management","System Administration"]
        },
       
    ];

    return (
        <div>
            {/* Other content on your projects page */}
            {projectData.map((project, index) => (
                <MatrixProjectCard 
                key={index}
                title={project.title}
                description={project.description}
                skills={project.skills}
            />
            ))}
        </div>
    );
}

export default Projects;
