import styled from 'styled-components';

const MainContent = () => {
    return (
      <MainContentContainer>
        <ProfilePicture />
        <Name>José Silva</Name>
        <Title>Software Engineer</Title>
        <div className="terminal">
            <div className="command-prompt">
              Hello, I’m Jose Silva from Porto. I’ve had the opportunity to study computer
                  science and have been fortunate enough to work on some software development
                  and machine learning projects. My academic journey took me through ISEP,
                  where I completed my Bachelor’s in Computer Science and later, a Master’s in
                  AI Engineering.
                  During this time, I’ve touched upon various programming languages, including
                  Python, Java, Rust, and C# .NET. One of my memorable experiences was a
                  project involving blockchain development, which was quite a learning curve. I’ve
                  also been involved in some research in energy informatics.
                  I’m genuinely interested in understanding more about the link between tradi-
                  tional software development and the evolving world of machine learning. While
                  I’m comfortable communicating in both Portuguese and English, I always ap-
                  preciate feedback and collaboration from peers to ensure clarity and mutual
                  understanding.
                  </div>
            {/* Add more lines as needed */}
        </div>
      </MainContentContainer>
    );
  };
  
export default MainContent;

  export const MainContentContainer = styled.section`
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ProfilePicture = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px solid var(--matrix-green);
    margin-bottom: 20px;
   
`;

export const Name = styled.h1`
    font-size: 3em;
`;

export const Title = styled.h2`
    font-size: 2em;
    margin-bottom: 20px;
`;

export const Description = styled.p`
    text-align: center;
    max-width: 800px;
    line-height: 1.5;
`;