import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Card = styled.div`
    position: relative;
    padding: 20px;
    border: 1px solid var(--matrix-green);
    margin-bottom: 20px;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);  // Initial slight green glow

    &:hover {
        background: rgba(0, 0, 0, 0.7);  // Darken the background on hover
        box-shadow: 0 0 15px rgba(0, 255, 0, 0.9);  // Intense green glow on hover
    }
`;
const MatrixText = styled.span`
    color: var(--matrix-green);
    transition: all 0.5s ease;

    ${Card}:hover & {
        display: none;
    }
`;

const OriginalText = styled.span`
    display: none;

    ${Card}:hover & {
        display: inline;
    }
`;

const MatrixProjectCard = ({ title, description, skills }) => {
    const [matrixTitle, setMatrixTitle] = useState(title);
    const [matrixDescription, setMatrixDescription] = useState(description);
    
    const chars = "ASDJASDHASBDHASDASHD8923791DASNJD;ASND102830123-21['ADASMJKANSD123612313=13123'1]'<Z<Z/<.,1,23102973";

    const randomizeCharacter = (char) => {
        if (char === ' ') return ' ';
        return chars[Math.floor(Math.random() * chars.length)];
    };

    const randomizeString = (str, setFunction) => {
        let intervals = [];
        for (let i = 0; i < str.length; i++) {
            let currentChar = str[i];
            intervals.push(setInterval(() => {
                setFunction(prevStr => 
                    prevStr.substr(0, i) + randomizeCharacter(currentChar) + prevStr.substr(i + 1)
                );
            }, Math.floor(Math.random() * 500) + 100));  // Random time between 100ms and 600ms
        }
        return intervals;
    };

    useEffect(() => {
        const titleIntervals = randomizeString(title, setMatrixTitle);
        const descriptionIntervals = randomizeString(description, setMatrixDescription);

        return () => {
            titleIntervals.forEach(interval => clearInterval(interval));
            descriptionIntervals.forEach(interval => clearInterval(interval));
        };
    }, [title, description]);

    return (
        <Card>
            <h3>
                <MatrixText>{matrixTitle}</MatrixText>
                <OriginalText>{title}</OriginalText>
            </h3>
            <p>
                <MatrixText>{matrixDescription}</MatrixText>
                <OriginalText>{description}</OriginalText>
            </p>
            <ul>
                {skills.map((skill, index) => (
                    <li key={index}>
                        <MatrixText>{randomizeCharacter(skill)}</MatrixText>
                        <OriginalText>{skill}</OriginalText>
                    </li>
                ))}
            </ul>
        </Card>
    );
};

export default MatrixProjectCard;