import styled from 'styled-components';

const ExperienceStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; // occupy full viewport height
    padding: 2rem;
    color: var(--matrix-green);
    background-color: var(--matrix-dark);

    h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        text-align: center; // Centering the heading text
    }

    .experience-card {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 600px; // or any other width you find suitable
        width: 100%;
        box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
        padding: 20px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5); // giving it a slightly dark background for visibility

        h2, h3, p, ul {
            margin-bottom: 1rem;
        }

        ul {
            padding-left: 1rem;

            li {
                margin-bottom: 0.5rem;
            }
        }
    }

    .magnify-icon {
        margin-left: 10px;
        cursor: pointer;
        transition: fill 0.3s ease;
    
        &:hover {
            fill: #66ff66;
        }
    }
    `;

function ExperiencePage() {
    return (
        <ExperienceStyled>
            <h1>Experience</h1>

            <div className="experience-card">
                <h2>C# .NET Windows Desktop Application Fullstack Developer</h2>
                <p>May 2023 - Current</p>
                <ul className="skills-list">
                    <li>C# and .NET expertise</li>
                    <li>Windows desktop application development</li>
                    <li>Full-stack software development</li>
                </ul>
            </div>

         
            <div className="experience-card">
                <h2>Blockchain Developer</h2>
                <p>Codice, Porto</p>
                <p>Aug 2021 - Sep 2022</p>
                <p>Role: Blockchain core developer using Substrate (rust) framework.</p>
                <ul className="skills-list">
                    <li>Rust and Substrate framework</li>
                    <li>Blockchain core development</li>
                    <li>Decentralized applications (dApps) development</li>
                </ul>
            </div>

            <div className="experience-card">
                <h2>Machine Learning Research</h2>
                <p>GECAD - Research Group on Intelligent Engineering, Porto</p>
                <p>Feb 2018 - Jul 2018</p>
                <p>Project: Ensemble Learning for predicting energy consumption in office buildings</p>
                <ul className="skills-list">
                    <li>Ensemble learning algorithms</li>
                    <li>Data analysis and forecasting</li>
                    <li>Python, Pytorch</li>
                </ul>
            </div>

        </ExperienceStyled>
    );
}

export default ExperiencePage;