import React from 'react';
import styled from 'styled-components';

const CodeChar = styled.div`
position: absolute;
animation: fallingCode 5s infinite;
font-size: 1.5em;
color: #33FF33;
top: 0;
left: ${props => props.left}%;
`;

const randomChar = () => String.fromCharCode(33 + Math.floor(Math.random() * 93));

const CodeFall = () => {
  // Here, we create 100 code characters with random left offsets.
  const chars = Array.from({ length: 100 }).map((_, index) => (
    <CodeChar key={index} left={Math.random() * 100}>
      {randomChar()}
    </CodeChar>
  ));

  return <>{chars}</>;
};

export default CodeFall;